import type { FormikProps } from "formik";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { withMask } from "use-mask-input";

import FormField from "../FormField";
import Input from "../Input";

import { CreditCardFormValues } from "../../types/credit-card";

export interface CreditCardFormProps extends FormikProps<CreditCardFormValues> {
  handleGetChargeOptions: (
    cardNumber: string,
    tokenId: string | null,
    promoCode?: string
  ) => void;
  showCardDetailCardholderNameInput?: boolean;
  showCardDetailEmailInput?: boolean;
}

const CreditCardForm = (props: CreditCardFormProps) => {
  const { t } = useTranslation("forms");

  const {
    values,
    errors,
    handleChange,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
    showCardDetailCardholderNameInput,
    showCardDetailEmailInput,
    touched
  } = props;

  const handleChangeCardNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cardNumber = e.target.value;

    setFieldValue("cardNumber", cardNumber);
    setFieldTouched("cardNumber", true);
  };

  // get charge options when card number is valid
  useEffect(() => {
    if (values.cardNumber.length >= 15 && values.cardNumber.length <= 19) {
      const timer = setTimeout(
        () => props.handleGetChargeOptions(values.cardNumber, null),
        500
      );

      return () => {
        clearTimeout(timer);
      };
    }
  }, [values.cardNumber]);

  const validThruMask = useMemo(() => {
    return "99/99";
  }, [values.validThru]);

  const cardholderNameInput = showCardDetailCardholderNameInput ? (
    <FormField
      label={t("Cardholder Name")}
      name="cardholderName"
      helpText={
        touched.cardholderName && errors.cardholderName
          ? t(errors.cardholderName as string, {
              field: t("Cardholder Name")
            })
          : undefined
      }
      state={
        touched.cardholderName && errors.cardholderName ? "error" : "default"
      }
    >
      <Input
        block
        disabled={isSubmitting}
        autoComplete="cardholder-name"
        name="cardholderName"
        value={values.cardholderName}
        onChange={handleChange}
        hasError={touched.cardholderName && !!errors.cardholderName}
        placeholder="John"
        data-testid="cardholder-name"
      />
    </FormField>
  ) : null;

  const emailInput = showCardDetailEmailInput ? (
    <FormField
      label={t("Email Address")}
      name="email"
      helpText={
        touched.email && errors.email
          ? t(errors.email as string, {
              field: t("Email Address")
            })
          : undefined
      }
      state={touched.email && errors.email ? "error" : "default"}
    >
      <Input
        block
        disabled={isSubmitting}
        autoComplete="email"
        name="email"
        value={values.email}
        onChange={handleChange}
        hasError={touched.email && !!errors.email}
        placeholder="payer@xendit.co"
        data-testid="email"
      />
    </FormField>
  ) : null;

  return (
    <div className="w-full flex flex-col space-y-2">
      <FormField
        label={t("Card Number")}
        name="cardNumber"
        helpText={
          touched.cardNumber && errors.cardNumber
            ? t(errors.cardNumber as string, {
                field: t("Card Number")
              })
            : undefined
        }
        state={errors.cardNumber && touched.cardNumber ? "error" : "default"}
      >
        <Input
          block
          type="text"
          inputMode="numeric"
          aria-label="Card number"
          aria-invalid={!!errors.cardNumber}
          autoComplete="cc-number"
          autoCorrect="off"
          disabled={isSubmitting}
          name="cardNumber"
          value={values.cardNumber}
          onChange={handleChangeCardNumber}
          hasError={touched.cardNumber && !!errors.cardNumber}
          placeholder="4000 0000 0000 1091"
          data-testid="card-number"
          ref={withMask("9999 9999 9999 9999 999", {
            placeholder: " ",
            showMaskOnHover: false,
            autoUnmask: true
          })}
        />
      </FormField>
      <div className="flex gap-x-4">
        <div className="basis-1/2">
          <FormField
            label={t("Valid Thru")}
            name="validThru"
            helpText={
              touched.validThru && errors.validThru
                ? t(errors.validThru as string, {
                    field: t("Valid Thru")
                  })
                : undefined
            }
            state={touched.validThru && errors.validThru ? "error" : "default"}
          >
            <Input
              block
              type="text"
              inputMode="numeric"
              aria-label="Card expiry"
              aria-invalid={!!errors.validThru}
              autoComplete="cc-exp"
              autoCorrect="off"
              disabled={isSubmitting}
              name="validThru"
              value={values.validThru}
              onChange={handleChange}
              hasError={touched.validThru && !!errors.validThru}
              placeholder="MM/YY"
              data-testid="valid-thru"
              ref={withMask(validThruMask, {
                placeholder: " ",
                showMaskOnHover: false,
                autoUnmask: true
              })}
            />
          </FormField>
        </div>
        <div className="basis-1/2">
          <FormField
            label="CVN"
            name="cvn"
            helpText={
              touched.cvn && errors.cvn
                ? t(errors.cvn as string, { field: "CVN" })
                : undefined
            }
            state={touched.cvn && errors.cvn ? "error" : "default"}
          >
            <Input
              block
              type="password"
              inputMode="numeric"
              pattern="[0-9]*"
              aria-label="CVN"
              aria-invalid={!!errors.cvn}
              autoComplete="cc-csc"
              autoCorrect="off"
              disabled={isSubmitting}
              name="cvn"
              maxLength={4}
              value={values.cvn}
              onChange={handleChange}
              hasError={touched.cvn && !!errors.cvn}
              placeholder="CVN"
              data-testid="cvv"
              ref={withMask("", {
                placeholder: " ",
                showMaskOnHover: false,
                autoUnmask: true,
                regex: "[0-9]*"
              })}
            />
          </FormField>
        </div>
      </div>
      {cardholderNameInput}
      {emailInput}
    </div>
  );
};

export default CreditCardForm;
