import { Flow, FlowStep } from "../../Flow";
import THDirectDebitFormA from "./THDirectDebitFormA";

export class THDirectDebitAFlow extends Flow {
  buildFlow() {
    this.clearFlow();
    this.appendStep({
      overlay: false,
      form: (
        <THDirectDebitFormA
          key="th-dd-form-a-step-1"
          paymentMethod={this.getSelectedPaymentMethod()}
          onSubmit={async (values) => {
            await this.postPaymentTokenAction(values);
            this.redirectToTokenAction();
          }}
          onCancel={this.resetSelection}
        />
      )
    });
    return this;
  }

  onSelectFlow(): FlowStep[] {
    return this.flow;
  }
}
