import OTPDialog from "../../../../../../components/OTPDialog";
import BRIandBCAForm from "./BRIAndBCAForm";
import { Flow } from "../../Flow";

export class BRIAndBCAFlow extends Flow {
  buildFlow() {
    this.clearFlow();
    this.appendStep({
      overlay: false,
      form: (
        <BRIandBCAForm
          key="dd-otp-form-step-1"
          paymentMethod={this.getSelectedPaymentMethod()}
          onSubmit={async (values) => {
            const response = await this.postPaymentTokenAction(values);
            response.isRight() && this.nextStep();
          }}
          onCancel={this.resetSelection}
        />
      )
    });
    this.appendStep({
      overlay: true,
      form: (
        <OTPDialog
          key="dd-otp-form-step-2"
          open={true}
          title="Enter OTP"
          description="Enter the 6-digit OTP we sent to your registered number to continue"
          numOTPInput={6}
          onVerifyOTP={async (otp) => {
            const response = await this.postPaymentTokenAuthAction(otp);
            response.isRight() && this.redirectToPollingAction();
          }}
          onClose={this.previousStep}
        />
      )
    });
    return this;
  }

  onSelectFlow() {
    return this.flow;
  }
}
