import { Flow } from "../../Flow";
import CardForm from "./CardForm";

export class CardFlow extends Flow {
  buildFlow() {
    this.clearFlow();
    this.appendStep({
      overlay: false,
      form: (
        <CardForm
          key="card-form-step-1"
          onSubmit={async (values) => {
            const response = await this.postPaymentTokenAction(values);
            response.isRight() && this.redirectToTokenAction();
          }}
          onCancel={this.resetSelection}
        />
      )
    });
    return this;
  }

  onSelectFlow() {
    return this.flow;
  }
}
