import { PaymentToken } from "../types";

export function findPTRedirectAction(paymentToken: PaymentToken) {
  const redirectAction = paymentToken.actions.find(
    (action) => action.type === "REDIRECT_CUSTOMER"
  );
  if (!redirectAction) {
    throw new Error(
      "There was a problem with the create payment token response. REDIRECT_CUSTOMER action type is not present",
      {
        cause: { token: paymentToken }
      }
    );
  }
  return redirectAction;
}

export function isPTStatusFailed(paymentToken: PaymentToken) {
  return paymentToken.status === "FAILED";
}
