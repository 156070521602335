import { Flow } from "../Flow";

export class RedirectFlow extends Flow {
  buildFlow() {
    return this;
  }

  onSelectFlow() {
    this.postPaymentTokenAction({}).then((response) => {
      response.isRight() && this.redirectToTokenAction();
    });
    return this.flow;
  }
}
