import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import { Country } from "country-state-city";

import Dropdown, { DropdownOption } from "../Dropdown";
import Input from "../Input";
import FormField from "../FormField";

import { CreditCardFormValues } from "../../types/credit-card";

type CountryOption = {
  label: string;
  value: string;
};

export interface CreditCardBillingDetailsProps
  extends FormikProps<CreditCardFormValues> {
  showCardDetailEmailInput?: boolean;
}

const CreditCardBillingDetails = (props: CreditCardBillingDetailsProps) => {
  const { t } = useTranslation("forms");

  // NOTE: If Forter is enabled, user will be required to input cardholder email, which is the same as what is required on the billing_details. So for a better UX, we don't need them to fill the same info twice. Therefore, we will only show this field if Forter is disabled. Note also that email on billing_details is optional.
  const emailBillingForm = !props.showCardDetailEmailInput ? (
    <FormField
      label={t("Email Address")}
      name="emailBilling"
      helpText={t(props.errors.emailBilling as string, {
        field: t("Email Address")
      })}
      state={props.errors.emailBilling ? "error" : "default"}
    >
      <Input
        block
        disabled={props.isSubmitting}
        autoComplete="email"
        name="emailBilling"
        value={props.values.emailBilling}
        onChange={props.handleChange}
        hasError={!!props.errors.emailBilling}
        placeholder="Email Address"
        data-testid="email"
      />
    </FormField>
  ) : null;

  return (
    <div className="flex flex-col space-y-4 pb-3">
      <div className="flex -mx-2">
        <div className="w-1/2 px-2">
          <FormField
            label={t("Given Name")}
            name="givenName"
            helpText={t(props.errors.givenName as string, {
              field: t("Given Name")
            })}
            state={props.errors.givenName ? "error" : "default"}
          >
            <Input
              block
              disabled={props.isSubmitting}
              autoComplete="given-name"
              name="givenName"
              value={props.values.givenName}
              onChange={props.handleChange}
              hasError={!!props.errors.givenName}
              placeholder="John"
              data-testid="first-name"
            />
          </FormField>
        </div>
        <div className="w-1/2 px-2">
          <FormField
            label={t("Last Name")}
            name="surname"
            helpText={t(props.errors.surname as string, {
              field: t("Last Name")
            })}
            state={props.errors.surname ? "error" : "default"}
          >
            <Input
              block
              disabled={props.isSubmitting}
              autoComplete="family-name"
              name="surname"
              value={props.values.surname}
              onChange={props.handleChange}
              hasError={!!props.errors.surname}
              placeholder="Doe"
              data-testid="surname"
            />
          </FormField>
        </div>
      </div>
      {emailBillingForm}
      <FormField
        label={t("Country")}
        name="country"
        helpText={props.errors.country}
        state={props.errors.country ? "error" : "default"}
      >
        <Dropdown
          block
          disabled={props.isSubmitting}
          name="country"
          data-testid="country"
          onChange={(selection) => {
            props.setFieldValue("country", (selection as CountryOption).value);
          }}
          value={props.values.country}
          options={Country.getAllCountries().map<CountryOption>((country) => ({
            label: `${country.flag} ${country.name}`,
            value: country.isoCode
          }))}
          keyExtractor={(country) => (country as CountryOption).value}
          renderOption={(country, helpers) => (
            <DropdownOption {...helpers}>
              {(country as CountryOption).label}
            </DropdownOption>
          )}
          renderSelection={(countryIsoCode) => {
            const country = Country.getCountryByCode(countryIsoCode as string);
            if (!country) {
              return t("Country");
            }
            return `${country.flag} ${country.name}`;
          }}
        />
      </FormField>
      <FormField
        label={t("Province/State")}
        name="provinceState"
        helpText={t(props.errors.provinceState as string, {
          field: t("Province/State")
        })}
        state={props.errors.provinceState ? "error" : "default"}
      >
        <Input
          block
          disabled={props.isSubmitting}
          autoComplete="province-state"
          name="provinceState"
          value={props.values.provinceState}
          onChange={props.handleChange}
          hasError={!!props.errors.provinceState}
          placeholder="Province/State"
          data-testid="province-state"
        />
      </FormField>
      <FormField
        label={t("City/District")}
        name="city"
        helpText={t(props.errors.city as string, {
          field: t("City/District")
        })}
        state={props.errors.city ? "error" : "default"}
      >
        <Input
          block
          disabled={props.isSubmitting}
          autoComplete="city"
          name="city"
          value={props.values.city}
          onChange={props.handleChange}
          hasError={!!props.errors.city}
          placeholder="City/District"
          data-testid="city"
        />
      </FormField>
      <FormField
        label={t("Street Line 1")}
        name="streetLine1"
        helpText={t(props.errors.streetLine1 as string, {
          field: t("Street Line 1")
        })}
        state={props.errors.streetLine1 ? "error" : "default"}
      >
        <Input
          block
          disabled={props.isSubmitting}
          autoComplete="street-address"
          name="streetLine1"
          value={props.values.streetLine1}
          onChange={props.handleChange}
          hasError={!!props.errors.streetLine1}
          placeholder="Address"
          data-testid="address-1"
        />
      </FormField>
      <FormField
        label={t("Postal Code")}
        name="postalCode"
        helpText={t(props.errors.postalCode as string, {
          field: t("Postal Code")
        })}
        state={props.errors.postalCode ? "error" : "default"}
      >
        <Input
          block
          disabled={props.isSubmitting}
          type="text"
          autoComplete="postal-code"
          name="postalCode"
          value={props.values.postalCode}
          onChange={props.handleChange}
          hasError={!!props.errors.postalCode}
          placeholder="Postal Code"
          data-testid="zipcode"
        />
      </FormField>
    </div>
  );
};

export default CreditCardBillingDetails;
