import {
  Business,
  Customer,
  PaymentMethod,
  Session,
  Setting,
  SucceededChannel
} from "../src/session/types";

const mockResponse: {
  session: Session;
  business: Business;
  paymentMethods: PaymentMethod[];
  settings: Setting;
  customer: Customer;
  succeeded_channel: SucceededChannel | undefined;
} = {
  session: {
    payment_session_id: "random-id",
    created: "2022-01-01T00:00:00Z",
    updated: "2022-01-01T00:00:00Z",
    reference_id: "random-reference-id",
    customer_id: "random-customer-id",
    session_type: "SAVE",
    currency: "IDR",
    amount: 100000,
    country: "ID",
    mode: "PAYMENT_LINK",
    channel_properties: {
      cards: {
        card_on_file_type: "CUSTOMER_UNSCHEDULED",
        mid_label: "random-mid-label",
        skip_three_ds: false,
        allowed_bins: []
      }
    },
    allowed_payment_channels: ["random-allowed-payment-channel"],
    expires_at: "2022-01-01T00:00:00Z",
    locale: "en",
    description: "random-description",
    items: [
      {
        type: "DIGITAL PRODUCT",
        name: "random-name",
        net_unit_amount: 100000,
        quantity: 1,
        url: "random-url",
        image_url: "random-image-url",
        category: "random-category",
        subcategory: "random-subcategory",
        description: "random-description",
        metadata: {}
      }
    ],
    success_return_url: "random-return-url",
    cancel_return_url: "random-return-url",
    status: "ACTIVE",
    payment_link_url: "random-payment-link-url",
    payment_token_id: "random-payment-token-id",
    payment_request_id: "random-payment-request-id",
    payment_channel_code: "random-payment-channel-code"
  },
  business: {
    name: "random-name",
    country_of_operation: "Indonesia",
    merchant_profile_picture_url:
      "https://du8nwjtfkinx.cloudfront.net/xendit.png"
  },
  paymentMethods: [
    {
      channel_code: "random-channel-code",
      pm_type: "random-pm-type",
      logo_url:
        "https://e7.pngegg.com/pngimages/439/265/png-clipart-money-bag-computer-icons-united-states-dollar-dollar-sign-money-bag-logo-grass.png"
    },
    {
      channel_code: "GCASH",
      pm_type: "EWALLET",
      logo_url: "https://assets.xendit.co/payment-channels/logos/gcash-logo.svg"
    },
    {
      channel_code: "BRI_DIRECT_DEBIT",
      pm_type: "DIRECT_DEBIT",
      logo_url:
        "https://assets.xendit.co/payment-channels/logos/dd-bri-logo.svg"
    },
    {
      channel_code: "CARDS",
      logo_url: "https://assets.xendit.co/payment-channels/logos/CARDS.svg",
      pm_type: "CARDS",
      card: {
        brands: [
          {
            logo_url: "/card-brands/mastercard.svg",
            name: "MASTERCARD"
          },
          {
            logo_url: "/card-brands/jcb.svg",
            name: "JCB"
          },
          {
            logo_url: "/card-brands/visa.svg",
            name: "VISA"
          }
        ]
      }
    }
  ],
  settings: {
    background_color: "#000000",
    button_and_accents_color: "#FFFFFF",
    expiry_time_format: "dunno",
    show_payment_link_expiry: true
  },
  customer: {
    type: "INDIVIDUAL",
    id: "random-id",
    email: "random-email",
    mobile_number: "random-mobile-number",
    phone_number: "random-phone-number",
    individual_detail: {
      given_names: "random-given-name",
      surname: "random surname"
    }
  },
  succeeded_channel: undefined
};

export class MockSessionResponse {
  private session: Session = { ...mockResponse.session };
  private business: Business = { ...mockResponse.business };
  private paymentMethods: PaymentMethod[] = [...mockResponse.paymentMethods];
  private settings: Setting = { ...mockResponse.settings };
  private customer: Customer = { ...mockResponse.customer };
  private succeededChannel: SucceededChannel | undefined;

  withActiveSession() {
    this.session.status = "ACTIVE";
    return this;
  }

  withCompletedSession() {
    this.session.status = "COMPLETED";
    return this;
  }

  withCanceledSession() {
    this.session.status = "CANCELED";
    return this;
  }

  withAllowedBins(allowedBins: string[] | undefined) {
    if (this.session.channel_properties.cards === undefined) {
      this.session.channel_properties.cards = {
        card_on_file_type: "CUSTOMER_UNSCHEDULED",
        mid_label: "random-mid-label",
        allowed_bins: allowedBins
      };
    } else {
      this.session.channel_properties.cards.allowed_bins = allowedBins;
    }
    return this;
  }

  withSucceededChannel(channelCode: string) {
    this.succeededChannel = {
      channel_code: channelCode,
      logo_url: `https://assets.xendit.co/payment-session/logos/${channelCode}.svg`
    };
    return this;
  }

  withCountryThailand() {
    this.business.country_of_operation = "Thailand";
    this.session.allowed_payment_channels = [
      "KTB_DIRECT_DEBIT",
      "KRUNGSRI_DIRECT_DEBIT"
    ];
    this.session.country = "TH";
    this.session.currency = "THB";
    this.paymentMethods = [
      {
        channel_code: "KTB_DIRECT_DEBIT",
        pm_type: "DIRECT_DEBIT",
        logo_url:
          "https://assets.xendit.co/payment-session/logos/KTB_DIRECT_DEBIT.svg"
      },
      {
        channel_code: "KRUNGSRI_DIRECT_DEBIT",
        pm_type: "DIRECT_DEBIT",
        logo_url:
          "https://assets.xendit.co/payment-session/logos/KRUNGSRI_DIRECT_DEBIT.svg"
      },
      {
        channel_code: "SCB_DIRECT_DEBIT",
        pm_type: "DIRECT_DEBIT",
        logo_url:
          "https://assets.xendit.co/payment-session/logos/SCB_DIRECT_DEBIT.svg"
      },
      {
        channel_code: "BBL_DIRECT_DEBIT",
        pm_type: "DIRECT_DEBIT",
        logo_url:
          "https://assets.xendit.co/payment-session/logos/BBL_DIRECT_DEBIT.svg"
      },
      {
        channel_code: "CARDS",
        logo_url: "https://assets.xendit.co/payment-channels/logos/CARDS.svg",
        pm_type: "CARDS",
        card: {
          brands: [
            {
              logo_url: "/card-brands/mastercard.svg",
              name: "MASTERCARD"
            },
            {
              logo_url: "/card-brands/jcb.svg",
              name: "JCB"
            },
            {
              logo_url: "/card-brands/visa.svg",
              name: "VISA"
            }
          ]
        }
      }
    ];
    return this;
  }

  getSession() {
    return this.session;
  }

  getBusiness() {
    return this.business;
  }

  getPaymentMethods() {
    return this.paymentMethods;
  }

  getSettings() {
    return this.settings;
  }

  getCustomer() {
    return this.customer;
  }

  getSucceededChannel() {
    return this.succeededChannel;
  }

  getFullResponse() {
    return {
      session: this.session,
      business: this.business,
      payment_methods: this.paymentMethods,
      settings: this.settings,
      customer: this.customer,
      succeeded_channel: this.succeededChannel
    };
  }

  getLimitedResponse() {
    return {
      session: {
        payment_session_id: this.session.payment_session_id,
        status: this.session.status
      }
    };
  }
}
