import { Dispatch, useRef } from "react";
import { postPaymentToken, postPaymentTokenAuth } from "../../../api/api";
import { useSessionContext } from "../../../context/context";
import {
  SessionActionTypes,
  SessionState
} from "../../../context/SessionContextInterface";
import { Either } from "../../../lib/either";
import { findPTRedirectAction } from "../../../lib/payment-token";
import {
  ErrorContent,
  ExtendedPaymentToken,
  PaymentMethod,
  PaymentToken,
  Session
} from "../../../types";

export interface FlowStep {
  overlay: boolean;
  form: JSX.Element;
}

export abstract class Flow {
  flow: FlowStep[] = [];

  private session: Session;

  private dispatch: Dispatch<{
    type: SessionActionTypes;
    payload: SessionState;
  }>;

  private channelCode: string | undefined;

  protected pmType: string | undefined;

  protected paymentMethods: PaymentMethod[];

  protected nextStep: () => void;

  protected previousStep: () => void;

  protected resetSelection: () => void;

  private paymentTokenResponse = useRef<PaymentToken | undefined>();

  private authPaymentTokenResponse = useRef<ExtendedPaymentToken | undefined>();

  constructor(props: {
    channelCode: string | undefined;
    pmType: string | undefined;
    nextStep: () => void;
    previousStep: () => void;
    resetSelection: () => void;
  }) {
    const { state, dispatch } = useSessionContext();
    const { session, paymentMethods } = state;
    if (!session || !paymentMethods) {
      throw new Error("Wizard was initialized with an invalid state", {
        cause: state
      });
    }
    this.session = session;
    this.paymentMethods = paymentMethods;
    this.dispatch = dispatch;
    this.channelCode = props.channelCode;
    this.pmType = props.pmType;
    this.nextStep = props.nextStep;
    this.previousStep = props.previousStep;
    this.resetSelection = props.resetSelection;
  }

  /** DISPATCH ACTIONS */
  protected showLoading = () => {
    this.dispatch({
      type: SessionActionTypes.SET_LOADING,
      payload: {
        isLoading: true
      }
    });
  };

  protected hideLoading = () => {
    this.dispatch({
      type: SessionActionTypes.SET_LOADING,
      payload: {
        isLoading: false
      }
    });
  };

  protected setErrorContent = (errorContent: ErrorContent) => {
    this.dispatch({
      type: SessionActionTypes.SET_ERROR_CONTENT,
      payload: {
        errorContent
      }
    });
  };

  /** STEP ACTIONS */
  protected postPaymentTokenAction = async (
    channelProperties: Record<string, unknown>
  ): Promise<Either<ErrorContent, PaymentToken>> => {
    this.showLoading();
    const response = await postPaymentToken({
      session_id: this.session.payment_session_id,
      channel_code: this.channelCode,
      channel_properties: channelProperties
    });
    this.hideLoading();

    if (response.isLeft()) {
      this.setErrorContent(response.getLeft());
      return response;
    }

    const paymentToken = response.getRight();
    this.paymentTokenResponse.current = paymentToken;
    return response;
  };

  protected postPaymentTokenAuthAction = async (
    authCode: string
  ): Promise<Either<ErrorContent, PaymentToken>> => {
    if (!this.paymentTokenResponse.current) {
      throw new Error("Payment Token is required");
    }

    this.showLoading();
    const response = await postPaymentTokenAuth({
      session_id: this.session.payment_session_id,
      payment_token_id: this.paymentTokenResponse.current.payment_token_id,
      auth_request: {
        auth_code: authCode
      }
    });
    this.hideLoading();

    if (response.isLeft()) {
      this.setErrorContent(response.getLeft());
      return response;
    }

    const token = response.getRight();
    this.authPaymentTokenResponse.current = token;
    return response;
  };

  protected redirectToTokenAction = async () => {
    if (!this.paymentTokenResponse.current) {
      throw new Error("Payment Token is required");
    }

    const redirectAction = findPTRedirectAction(
      this.paymentTokenResponse.current
    );
    window.location.href = redirectAction.value;
  };

  protected redirectToPollingAction = () => {
    if (!this.authPaymentTokenResponse.current) {
      throw new Error("Payment Token is required");
    }

    this.dispatch({
      type: SessionActionTypes.REDIRECT_TO_POLLING,
      payload: {
        tokenRequestId:
          this.authPaymentTokenResponse.current.session_token_request_id
      }
    });
  };

  /** CLASS METHODS */
  protected clearFlow() {
    this.flow = [];
  }

  protected appendStep(step: FlowStep) {
    this.flow.push(step);
  }

  protected getSelectedPaymentMethod() {
    return this.paymentMethods?.find(
      (pm) => pm.channel_code === this.channelCode
    );
  }

  abstract buildFlow(): this;

  abstract onSelectFlow(): FlowStep[];
}
