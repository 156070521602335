/* eslint-disable @typescript-eslint/no-empty-function */
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useReducer,
  useRef
} from "react";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { Buffer } from "buffer";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import omitBy from "lodash/omitBy";

import { usePaymentLink } from "../PaymentLinkContext";

import {
  createCardCybsCharge,
  createSignature,
  getCreditCardChargeOptions
} from "../../utils/fetch-resource";
import { APP_ENV_MODE } from "../../utils/constants";
import {
  AuthenticationResponse,
  ChargeOptionsParams,
  CreditCardChargeOptions,
  CreditCardPaymentOnSubmit,
  CreditCardPromotion,
  CreditCardSignature,
  CreditCardSignaturePayload,
  CreditCardValidationErrors,
  CybersourceCharge,
  CybersourceChargeRequest,
  FailureReasonDetail,
  SafeAcceptancePayload,
  TokenizationData,
  TokenizationRequest
} from "../../types/credit-card";

import Dialog from "../../components/Dialog";

export type SetErrorPayload = {
  error: ErrorMessage;
};

export type CreditCardPaymentContextValues =
  | {
      status: "idle";
      invoiceId: string;
      chargeOptions: CreditCardChargeOptions | null;
      chargeOptionsParams: ChargeOptionsParams | null;
      isFetchingChargeOptions: boolean;
      promotion: CreditCardPromotion | null;
      tokenData: TokenizationData | null;
      chargeData: null;
      creditCardSignature: null;
      error: null;
      onCreditCardError: (payload: ErrorMessage) => void;
      onTokenInReview: (payload: TokenizationData) => void;
      onTokenVerified: (payload: TokenizationData) => void;
      onCreateCybersourceCharge: (payload: CybersourceChargeRequest) => void;
      onCreateSignature: (payload: CreditCardSignaturePayload) => void;
      onCreateToken: (tokenData: Partial<TokenizationRequest>) => void;
      onSubmitPayment: (creditCardData: CreditCardPaymentOnSubmit) => void;
      onSetCreditCardPromotion: (promotion: CreditCardPromotion | null) => void;
      onGetChargeOptions: (
        cardNumber: string,
        tokenId: string | null,
        promoCode?: string
      ) => void;
      creditCardValidationErrors: CreditCardValidationErrors | null;
    }
  | {
      status: "in_review";
      invoiceId: string;
      chargeOptions: CreditCardChargeOptions | null;
      chargeOptionsParams: ChargeOptionsParams | null;
      isFetchingChargeOptions: boolean;
      promotion: CreditCardPromotion | null;
      tokenData: TokenizationData;
      chargeData: null;
      creditCardSignature: null;
      error: null;
      onCreditCardError: (payload: ErrorMessage) => void;
      onTokenInReview: (payload: TokenizationData) => void;
      onTokenVerified: (payload: TokenizationData) => void;
      onCreateCybersourceCharge: (payload: CybersourceChargeRequest) => void;
      onCreateSignature: (payload: CreditCardSignaturePayload) => void;
      onCreateToken: (tokenData: Partial<TokenizationRequest>) => void;
      onSubmitPayment: (creditCardData: CreditCardPaymentOnSubmit) => void;
      onSetCreditCardPromotion: (promotion: CreditCardPromotion | null) => void;
      onGetChargeOptions: (
        cardNumber: string,
        tokenId: string | null,
        promoCode?: string
      ) => void;
      creditCardValidationErrors: CreditCardValidationErrors | null;
    }
  | {
      status: "verified";
      invoiceId: string;
      chargeOptions: null;
      chargeOptionsParams: ChargeOptionsParams | null;
      isFetchingChargeOptions: boolean;
      promotion: CreditCardPromotion | null;
      tokenData: TokenizationData;
      chargeData: null;
      creditCardSignature: null;
      error: null;
      onCreditCardError: (payload: ErrorMessage) => void;
      onTokenInReview: (payload: TokenizationData) => void;
      onTokenVerified: (payload: TokenizationData) => void;
      onCreateCybersourceCharge: (payload: CybersourceChargeRequest) => void;
      onCreateSignature: (payload: CreditCardSignaturePayload) => void;
      onCreateToken: (tokenData: Partial<TokenizationRequest>) => void;
      onSubmitPayment: (creditCardData: CreditCardPaymentOnSubmit) => void;
      onSetCreditCardPromotion: (promotion: CreditCardPromotion | null) => void;
      onGetChargeOptions: (
        cardNumber: string,
        tokenId: string | null,
        promoCode?: string
      ) => void;
      creditCardValidationErrors: CreditCardValidationErrors | null;
    }
  | {
      status: "processing";
      invoiceId: string;
      chargeOptions: null;
      chargeOptionsParams: ChargeOptionsParams | null;
      isFetchingChargeOptions: boolean;
      promotion: CreditCardPromotion | null;
      tokenData: TokenizationData;
      chargeData: null;
      creditCardSignature: null;
      error: null;
      onCreditCardError: (payload: ErrorMessage) => void;
      onTokenInReview: (payload: TokenizationData) => void;
      onTokenVerified: (payload: TokenizationData) => void;
      onCreateCybersourceCharge: (payload: CybersourceChargeRequest) => void;
      onCreateSignature: (payload: CreditCardSignaturePayload) => void;
      onCreateToken: (tokenData: Partial<TokenizationRequest>) => void;
      onSubmitPayment: (creditCardData: CreditCardPaymentOnSubmit) => void;
      onSetCreditCardPromotion: (promotion: CreditCardPromotion | null) => void;
      onGetChargeOptions: (
        cardNumber: string,
        tokenId: string | null,
        promoCode?: string
      ) => void;
      creditCardValidationErrors: CreditCardValidationErrors | null;
    }
  | {
      status: "resolved";
      invoiceId: string;
      chargeOptions: null;
      chargeOptionsParams: ChargeOptionsParams | null;
      isFetchingChargeOptions: boolean;
      promotion: CreditCardPromotion | null;
      tokenData: TokenizationData;
      chargeData: CybersourceCharge;
      creditCardSignature?: CreditCardSignature;
      error: null;
      onCreditCardError: (payload: ErrorMessage) => void;
      onTokenInReview: (payload: TokenizationData) => void;
      onTokenVerified: (payload: TokenizationData) => void;
      onCreateCybersourceCharge: (payload: CybersourceChargeRequest) => void;
      onCreateSignature: (payload: CreditCardSignaturePayload) => void;
      onCreateToken: (tokenData: Partial<TokenizationRequest>) => void;
      onSubmitPayment: (creditCardData: CreditCardPaymentOnSubmit) => void;
      onSetCreditCardPromotion: (promotion: CreditCardPromotion | null) => void;
      onGetChargeOptions: (
        cardNumber: string,
        tokenId: string | null,
        promoCode?: string
      ) => void;
      creditCardValidationErrors: CreditCardValidationErrors | null;
    }
  | {
      status: "error";
      invoiceId: string;
      chargeOptions: null;
      chargeOptionsParams: ChargeOptionsParams | null;
      isFetchingChargeOptions: boolean;
      promotion: CreditCardPromotion | null;
      tokenData: null;
      chargeData: null;
      creditCardSignature: null;
      error: ErrorMessage;
      onCreditCardError: (payload: ErrorMessage) => void;
      onTokenInReview: (payload: TokenizationData) => void;
      onTokenVerified: (payload: TokenizationData) => void;
      onCreateCybersourceCharge: (payload: CybersourceChargeRequest) => void;
      onCreateSignature: (payload: CreditCardSignaturePayload) => void;
      onCreateToken: (tokenData: Partial<TokenizationRequest>) => void;
      onSubmitPayment: (creditCardData: CreditCardPaymentOnSubmit) => void;
      onSetCreditCardPromotion: (promotion: CreditCardPromotion | null) => void;
      onGetChargeOptions: (
        cardNumber: string,
        tokenId: string | null,
        promoCode?: string
      ) => void;
      creditCardValidationErrors: CreditCardValidationErrors | null;
    };

export type CreditCardPaymentReducerState =
  | {
      status: "idle";
      invoiceId: string;
      chargeOptions: CreditCardChargeOptions | null;
      chargeOptionsParams: ChargeOptionsParams | null;
      isFetchingChargeOptions: boolean;
      promotion: CreditCardPromotion | null;
      tokenData: TokenizationData | null;
      chargeData: null;
      creditCardSignature: null;
      error: null;
    }
  | {
      status: "in_review";
      invoiceId: string;
      chargeOptions: CreditCardChargeOptions | null;
      chargeOptionsParams: ChargeOptionsParams | null;
      isFetchingChargeOptions: boolean;
      promotion: CreditCardPromotion | null;
      tokenData: TokenizationData;
      chargeData: null;
      creditCardSignature: null;
      error: null;
    }
  | {
      status: "verified";
      invoiceId: string;
      chargeOptions: CreditCardChargeOptions | null;
      chargeOptionsParams: ChargeOptionsParams | null;
      isFetchingChargeOptions: boolean;
      promotion: CreditCardPromotion | null;
      tokenData: TokenizationData;
      chargeData: null;
      creditCardSignature: null;
      error: null;
    }
  | {
      status: "processing";
      invoiceId: string;
      chargeOptions: CreditCardChargeOptions | null;
      chargeOptionsParams: ChargeOptionsParams | null;
      isFetchingChargeOptions: boolean;
      promotion: CreditCardPromotion | null;
      tokenData: TokenizationData;
      chargeData: null;
      creditCardSignature: null;
      error: null;
    }
  | {
      status: "resolved";
      invoiceId: string;
      chargeOptions: null;
      chargeOptionsParams: ChargeOptionsParams | null;
      isFetchingChargeOptions: boolean;
      promotion: null;
      tokenData: TokenizationData;
      chargeData: CybersourceCharge;
      creditCardSignature: CreditCardSignature;
      error: null;
    }
  | {
      status: "error";
      invoiceId: string;
      chargeOptions: null;
      chargeOptionsParams: ChargeOptionsParams | null;
      isFetchingChargeOptions: boolean;
      promotion: null;
      tokenData: null;
      chargeData: null;
      creditCardSignature: null;
      error: ErrorMessage;
    };

export type CreditCardPaymentReducerAction =
  | { type: "set_charge_options"; payload: CreditCardChargeOptions }
  | { type: "set_fetching_charge_options"; payload: ChargeOptionsParams }
  | { type: "set_promotion"; payload: CreditCardPromotion | null }
  | { type: "set_token_data"; payload: TokenizationData }
  | { type: "set_in_review"; payload: Partial<TokenizationData> }
  | { type: "set_verified"; payload: TokenizationData }
  | { type: "set_processing" }
  | { type: "set_signature"; payload: CreditCardSignature }
  | { type: "set_resolved"; payload: CybersourceCharge }
  | { type: "set_error"; payload: ErrorMessage }
  | { type: "dismiss_error" };

const initialState = {
  status: "idle",
  invoiceId: "",
  chargeOptions: null,
  chargeOptionsParams: null,
  isFetchingChargeOptions: false,
  promotion: null,
  tokenData: null,
  chargeData: null,
  creditCardSignature: null,
  error: null,
  creditCardValidationErrors: null,
  onCreditCardError: () => {},
  onTokenInReview: () => {},
  onTokenVerified: () => {},
  onCreateCybersourceCharge: () => {},
  onCreateSignature: () => {},
  onCreateToken: () => {},
  onSubmitPayment: () => {},
  onGetChargeOptions: () => {},
  onSetCreditCardPromotion: () => {}
} satisfies CreditCardPaymentContextValues;

const CreditCardPaymentContext =
  createContext<CreditCardPaymentContextValues>(initialState);

const CreditCardPaymentReducer = (
  state: CreditCardPaymentReducerState,
  action: CreditCardPaymentReducerAction
): CreditCardPaymentReducerState => {
  switch (action.type) {
    case "set_charge_options":
      return {
        ...state,
        chargeOptions: action.payload,
        isFetchingChargeOptions: false,
        promotion: null,
        chargeData: null,
        creditCardSignature: null
      };
    case "set_fetching_charge_options":
      return {
        ...state,
        isFetchingChargeOptions: true,
        chargeOptionsParams: action.payload
      };
    case "set_token_data":
      return {
        ...state,
        status: "idle",
        tokenData: action.payload,
        promotion: null,
        chargeData: null,
        creditCardSignature: null,
        error: null
      };
    case "set_promotion":
      return {
        ...state,
        status: "idle",
        promotion: action.payload,
        isFetchingChargeOptions: false,
        tokenData: null,
        chargeData: null,
        creditCardSignature: null,
        error: null
      };
    case "set_signature":
      return {
        ...state,
        status: "resolved",
        creditCardSignature: action.payload,
        error: null
      };
    case "set_in_review":
      return {
        ...state,
        status: "in_review",
        tokenData: action.payload,
        chargeData: null,
        creditCardSignature: null,
        error: null
      };
    case "set_verified":
      return {
        ...state,
        status: "verified",
        tokenData: action.payload,
        chargeData: null,
        creditCardSignature: null,
        error: null
      };
    case "set_processing":
      return {
        ...state,
        status: "processing",
        chargeOptions: null,
        chargeOptionsParams: null,
        chargeData: null,
        creditCardSignature: null,
        error: null
      };
    case "set_resolved":
      return {
        ...state,
        status: "resolved",
        chargeData: action.payload,
        chargeOptions: null,
        chargeOptionsParams: null,
        error: null
      };
    case "set_error":
      return {
        ...state,
        status: "error",
        chargeOptions: null,
        chargeOptionsParams: null,
        isFetchingChargeOptions: false,
        promotion: null,
        tokenData: null,
        chargeData: null,
        creditCardSignature: null,
        error: action.payload
      };
    case "dismiss_error":
      return {
        ...state,
        status: "idle",
        tokenData: null,
        chargeData: null,
        creditCardSignature: null,
        error: null
      };
    default:
      return state;
  }
};

export type CreditCardPaymentProviderProps = {
  children: ReactNode;
};

const CreditCardPaymentProvider = ({
  children
}: CreditCardPaymentProviderProps) => {
  const { i18n, t } = useTranslation(["credit-card", "common"]);

  const {
    paymentLink: { invoice, cards_settings, public_api_key }
  } = usePaymentLink();

  const [state, dispatch] = useReducer(CreditCardPaymentReducer, initialState);

  const abortControllerRef = useRef<AbortController>();

  const shouldAuthenticate = get(
    invoice,
    "should_authenticate_credit_card",
    get(cards_settings, "should_authenticate", true)
  );

  const shouldChargeMultipleUseToken = get(
    invoice,
    "should_charge_multiple_use_token",
    false
  );

  const handleSetPromotion = useCallback(
    (promotion: CreditCardPromotion | null) => {
      dispatch({ type: "set_promotion", payload: promotion });
    },
    [dispatch]
  );

  const handleInReviewToken = useCallback(
    (tokenData: Partial<TokenizationData>) => {
      dispatch({ type: "set_in_review", payload: tokenData });

      const threeDsInlineFrame = document.getElementById(
        "three-d-secure-inline-frame"
      );
      threeDsInlineFrame?.classList.add(
        "py-8",
        "w-full",
        "h-5/6",
        "bg-white",
        "overflow-hidden"
      );

      const iframe = document.createElement("iframe");
      iframe.src = tokenData.payer_authentication_url;
      iframe.className =
        "mx-auto w-[390px] h-[800px] md:w-[600px] lg:w-[500px] xl:w-[600px] border border-xen-gray-500";
      iframe.setAttribute("data-testid", "iframe-3ds");

      threeDsInlineFrame?.appendChild(iframe);
    },
    [dispatch]
  );

  const handleVerifiedToken = useCallback((tokenData: TokenizationData) => {
    dispatch({ type: "set_verified", payload: tokenData });
  }, []);

  const handleCreditCardError = useCallback(
    (error: ErrorMessage) => {
      dispatch({
        type: "set_error",
        payload: {
          title:
            t(`${error?.title}.title`) === `${error?.title}.title`
              ? t("Transaction Failed", { ns: "common" })
              : t(`${error?.title}.title`),
          body:
            t(`${error?.body}.body`) === `${error?.body}.body`
              ? t("Please try again or use another payment method.", {
                  ns: "common"
                })
              : t(`${error?.body}.body`)
        }
      });

      const threeDsInlineFrame = document.getElementById(
        "three-d-secure-inline-frame"
      );

      threeDsInlineFrame?.classList.remove(
        "py-8",
        "w-full",
        "h-5/6",
        "bg-white",
        "overflow-hidden"
      );

      if (threeDsInlineFrame?.hasChildNodes()) {
        threeDsInlineFrame?.removeChild(threeDsInlineFrame.childNodes[0]);
      }
    },
    [dispatch]
  );

  const handleCreateCybersourceCharge = useCallback(
    async (payload: CybersourceChargeRequest) => {
      dispatch({ type: "set_processing" });

      const threeDsInlineFrame = document.getElementById(
        "three-d-secure-inline-frame"
      );
      const cardsProcessingGraphic = document.getElementById(
        "cards-processing-graphic"
      );

      threeDsInlineFrame?.classList.remove(
        "py-8",
        "w-full",
        "h-5/6",
        "bg-white",
        "overflow-hidden"
      );

      if (threeDsInlineFrame?.hasChildNodes()) {
        threeDsInlineFrame?.removeChild(threeDsInlineFrame.childNodes[0]);
      }

      cardsProcessingGraphic?.classList.remove("hidden");
      cardsProcessingGraphic?.classList.add(
        "flex",
        "flex-col",
        "justify-center",
        "items-center",
        "h-5/6",
        "px-4",
        "lg:px-0",
        "pt-12",
        "lg:pt-4"
      );

      abortControllerRef.current = new AbortController();

      try {
        const creditCardPayload = {
          credit_card_token: payload.credit_card_token,
          authentication_id: payload.authentication_id,
          should_authenticate_credit_card:
            payload.should_authenticate_credit_card,
          metadata: {
            _xendit_fp_session_id: payload.fp_session_id
          }
        } as Omit<
          CybersourceChargeRequest,
          "set_submitting" | "billing_details_required"
        >;

        if (payload.billing_details_required) {
          creditCardPayload.billing_details = payload.billing_details;
        }

        if (payload.installment) {
          creditCardPayload.installment = {
            count: payload.installment.count,
            interval: payload.installment.interval,
            code: payload.installment.code
          };
        }

        if (payload.promotion_reference_id) {
          creditCardPayload.promotion_reference_id =
            payload.promotion_reference_id;
        }

        const cybersourceCharge = await createCardCybsCharge<CybersourceCharge>(
          invoice.id,
          creditCardPayload,
          {
            abortSignal: abortControllerRef.current.signal
          }
        );

        const failureReasonIsAvailable =
          cybersourceCharge.status === "FAILED" &&
          cybersourceCharge.failure_reason_details !== undefined;

        if (failureReasonIsAvailable) {
          const failureReasonDetails =
            cybersourceCharge.failure_reason_details as Record<
              string,
              FailureReasonDetail
            >;
          const localizedRecommendation = failureReasonDetails[i18n.language];

          cardsProcessingGraphic?.classList.add("hidden");
          cardsProcessingGraphic?.classList.remove(
            "flex",
            "flex-col",
            "justify-center",
            "items-center",
            "h-5/6",
            "px-4",
            "lg:px-0",
            "pt-12",
            "lg:pt-4"
          );

          payload.set_submitting(false);

          return dispatch({
            type: "set_error",
            payload: {
              title: t("Transaction Failed", { ns: "common" }),
              body: localizedRecommendation.recommendation_cardholder
            }
          });
        }

        dispatch({
          type: "set_resolved",
          payload: cybersourceCharge
        });
      } catch (err) {
        cardsProcessingGraphic?.classList.add("hidden");
        cardsProcessingGraphic?.classList.remove(
          "flex",
          "flex-col",
          "justify-center",
          "items-center",
          "h-5/6",
          "px-4",
          "lg:px-0",
          "pt-12",
          "lg:pt-4"
        );

        let errorMessage = "There was an error while making transaction";

        if (err instanceof AxiosError) {
          const errorResponse = err.response?.data;
          errorMessage = errorResponse.message;
        }

        dispatch({
          type: "set_error",
          payload: {
            title: t("Transaction Failed", { ns: "common" }),
            body: t("Please retry with another payment method", {
              ns: "common"
            })
          }
        });
      }

      payload.set_submitting(false);
    },
    [dispatch]
  );

  const handleCreateSafeAcceptanceCharge = useCallback(
    async (
      payload: CreditCardSignaturePayload & Partial<SafeAcceptancePayload>
    ) => {
      abortControllerRef.current = new AbortController();

      try {
        const signaturePayload = {} as any;

        if (!isEmpty(payload.promotion)) {
          signaturePayload.promotion = payload.promotion;
        }

        const creditCardSignature = await createSignature<CreditCardSignature>(
          invoice.id,
          signaturePayload,
          {
            abortSignal: abortControllerRef.current.signal
          }
        );

        const safeAcceptanceBody = {
          amount: !isEmpty(payload.promotion)
            ? payload.promotion.final_amount
            : invoice.amount,
          currency: invoice.currency,
          reference_id: invoice.external_id,
          token_id: payload.token_id,
          card_cvn: payload.card_cvn,
          should_authenticate: cards_settings?.should_authenticate,
          authorization: `Basic ${Buffer.from(
            public_api_key?.api_key + `:`
          ).toString("base64")}`,
          return_url: creditCardSignature.return_url,
          request_timestamp: creditCardSignature.request_timestamp,
          signed_field_names: creditCardSignature.signed_field_names,
          signature: creditCardSignature.signature,
          client_id: invoice.id,
          client_type: "INVOICE",
          internal_metadata: JSON.stringify({ invoice: { id: invoice.id } })
        } as SafeAcceptancePayload;

        if (
          !isEmpty(payload.installment_code) &&
          !isEmpty(payload.installment_count)
        ) {
          safeAcceptanceBody["installment_count"] = payload.installment_count;
          safeAcceptanceBody["installment_interval"] =
            payload.installment_interval;
          safeAcceptanceBody["installment_code"] = payload.installment_code;
        }

        safeAcceptanceBody["given_names"] = payload.given_names;
        safeAcceptanceBody["surname"] = payload.surname;
        safeAcceptanceBody["email"] = payload.email;

        safeAcceptanceBody["country"] = payload.country;
        safeAcceptanceBody["street_line1"] = payload.street_line1;
        safeAcceptanceBody["city"] = payload.city;
        safeAcceptanceBody["province_state"] = payload.province_state as string;
        safeAcceptanceBody["postal_code"] = payload.postal_code;

        if (!isEmpty(payload.promotion)) {
          const { reference_id, original_amount } = payload.promotion;

          safeAcceptanceBody["promotion_reference_id"] = reference_id;
          safeAcceptanceBody["promotion_original_amount"] = original_amount;
        }

        if (payload.use_reward) {
          safeAcceptanceBody["use_reward"] = payload.use_reward;
        }

        const cleanSafeAcceptanceBody = omitBy(
          safeAcceptanceBody,
          isUndefined
        ) as SafeAcceptancePayload;

        let safeAcceptanceBaseUrl;
        if (APP_ENV_MODE.includes("staging")) {
          safeAcceptanceBaseUrl = "https://api.stg.tidnex.dev";
        } else {
          safeAcceptanceBaseUrl = "https://api.xendit.co";
        }

        const safeAcceptanceForm = document.createElement("form");
        safeAcceptanceForm.action = `${safeAcceptanceBaseUrl}/credit_cards/safe_acceptance`;
        safeAcceptanceForm.method = "POST";
        safeAcceptanceForm.classList.add("hidden");

        (
          Object.keys(cleanSafeAcceptanceBody) as Array<
            keyof typeof cleanSafeAcceptanceBody
          >
        ).map((key) => {
          const input = document.createElement("input");
          input.setAttribute("value", cleanSafeAcceptanceBody[key] as string);
          input.setAttribute("name", key);
          safeAcceptanceForm.appendChild(input);
        });

        document.body.append(safeAcceptanceForm);
        safeAcceptanceForm.submit();
      } catch (err) {
        let errorMessage = "There was an error while making transaction";

        if (err instanceof AxiosError) {
          const errorResponse = err.response?.data;
          errorMessage = errorResponse.message;
        }

        dispatch({
          type: "set_error",
          payload: {
            title: t("Transaction Failed", { ns: "common" }),
            body: t("Please retry with another payment method", {
              ns: "common"
            })
          }
        });
      }
    },
    [dispatch]
  );

  const handleGetChargeOptions = useCallback(
    async (cardNumber: string, tokenId: string | null, promoCode?: string) => {
      dispatch({
        type: "set_fetching_charge_options",
        payload: { cardNumber }
      });
      abortControllerRef.current = new AbortController();

      const params = [
        ["business_id", invoice.user_id],
        ["amount", invoice.amount.toString()],
        ["currency", invoice.currency as string]
      ];

      if (!isEmpty(tokenId)) {
        params.push(["token_id", tokenId as string]);
      } else {
        params.push(["bin", cardNumber.slice(0, 6)]); // first six digit of card number
      }

      if (!isEmpty(promoCode)) {
        params.push(["promo_code", promoCode as string]);
      }

      const query = new URLSearchParams(params);

      const chargeOptions =
        await getCreditCardChargeOptions<CreditCardChargeOptions>(
          invoice.id,
          query.toString(),
          {
            abortSignal: abortControllerRef.current.signal
          }
        );

      // set promotion if promo code applicable
      if (!isEmpty(promoCode) && chargeOptions.promotions.length > 0) {
        dispatch({
          type: "set_promotion",
          payload: chargeOptions.promotions[0]
        });
      }

      // keep charge options without applied promo code
      if (isEmpty(promoCode)) {
        dispatch({
          type: "set_charge_options",
          payload: chargeOptions
        });
      }
    },
    [dispatch]
  );

  // createTokenForSafeAcceptanceChargeOptions
  const handleCreateToken = useCallback(
    (tokenData: Partial<TokenizationRequest>) => {
      const fingerprintSessionId = Xendit.fingerprint.getSessionID();

      Xendit.card.createToken(
        {
          amount: invoice.amount,
          currency: invoice.currency,
          card_number: tokenData.card_number,
          card_exp_month: tokenData.card_exp_month,
          card_exp_year: tokenData.card_exp_year,
          card_cvn: tokenData.card_cvn,
          is_multiple_use: true,
          should_authenticate: false,
          fp_session_id: fingerprintSessionId,
          client_id: invoice.id
        },
        (err: Record<string, unknown>, creditCardToken: TokenizationData) => {
          if (err) {
            return handleCreditCardError({
              title: err.error_code as string,
              body: err.message as string
            });
          }

          if (creditCardToken.status === "VERIFIED") {
            dispatch({
              type: "set_token_data",
              payload: creditCardToken
            });
            handleGetChargeOptions(
              tokenData.card_number as string,
              creditCardToken.id
            );
          }
        }
      );
    },
    [dispatch]
  );

  const handleSubmitPayment = useCallback(
    async (creditCardData: CreditCardPaymentOnSubmit) => {
      const fingerprintSessionId = Xendit.fingerprint.getSessionID();

      Xendit.card.createToken(
        {
          amount: creditCardData.promotion
            ? creditCardData.promotion.final_amount
            : invoice.amount,
          currency: invoice.currency,
          card_number: creditCardData.card_number,
          card_exp_month: creditCardData.card_exp_month,
          card_exp_year: creditCardData.card_exp_year,
          card_cvn: creditCardData.card_cvn,
          is_multiple_use:
            cards_settings?.cards_payment_channel === "SAFE_ACCEPTANCE"
              ? true
              : shouldChargeMultipleUseToken,
          should_authenticate:
            cards_settings?.cards_payment_channel === "SAFE_ACCEPTANCE"
              ? false
              : shouldAuthenticate,
          fp_session_id: fingerprintSessionId,
          card_holder_name: creditCardData.cardholder_name,
          card_holder_email: creditCardData.email,
          client_id: invoice.id
        },
        async (
          err: Record<string, unknown>,
          creditCardToken: TokenizationData
        ) => {
          if (err) {
            creditCardData.set_submitting(false);

            return handleCreditCardError({
              title: err.error_code as string,
              body: err.message as string
            });
          }

          if (creditCardToken.status === "VERIFIED") {
            if (cards_settings?.cards_payment_channel === "SAFE_ACCEPTANCE") {
              handleCreateSafeAcceptanceCharge({
                token_id: creditCardToken.id,
                card_cvn: creditCardData.card_cvn,
                installment_code: creditCardData.installment_code,
                installment_count: creditCardData.installment_count,
                installment_interval: creditCardData.installment_interval,
                promotion: creditCardData.promotion as CreditCardPromotion,
                given_names: creditCardData.billing_details?.given_names,
                surname: creditCardData.billing_details?.surname,
                email: creditCardData.billing_details?.email,
                country: creditCardData.billing_details?.address.country,
                street_line1:
                  creditCardData.billing_details?.address.street_line1,
                city: creditCardData.billing_details?.address.city,
                province_state:
                  creditCardData.billing_details?.address.province_state,
                postal_code: creditCardData.billing_details?.address.postal_code
                // use_reward: false
              });
            }

            if (cards_settings?.cards_payment_channel === "CYBERSOURCE") {
              // handle multiple time use cybs charge
              if (shouldChargeMultipleUseToken) {
                const authenticationData = {
                  amount: invoice.amount,
                  currency: invoice.currency,
                  token_id: creditCardToken.id,
                  client_id: invoice.id
                };

                Xendit.card.createAuthentication(
                  authenticationData,
                  async (
                    err: Record<string, unknown>,
                    authentication: AuthenticationResponse
                  ) => {
                    if (err) {
                      creditCardData.set_submitting(false);

                      return handleCreditCardError({
                        title: err.error_code as string,
                        body: err.message as string
                      });
                    }

                    if (authentication.status === "IN_REVIEW") {
                      // handle 3ds
                      handleInReviewToken(
                        authentication as Partial<TokenizationData>
                      );
                    } else if (authentication.status === "VERIFIED") {
                      handleCreateCybersourceCharge({
                        credit_card_token: creditCardToken.id,
                        should_authenticate_credit_card: shouldAuthenticate,
                        fp_session_id: fingerprintSessionId,
                        authentication_id: authentication.id,
                        billing_details: creditCardData.billing_details,
                        installment: creditCardData.installment,
                        promotion_reference_id:
                          creditCardData.promotion?.reference_id,
                        billing_details_required:
                          creditCardData.billing_details_required,
                        cardholder_name: creditCardData.cardholder_name,
                        email: creditCardData.email,
                        set_submitting: creditCardData.set_submitting
                      });
                    }
                  }
                );
              } else {
                // handle one time cybs charge
                handleCreateCybersourceCharge({
                  credit_card_token: creditCardToken.id,
                  should_authenticate_credit_card: shouldAuthenticate,
                  fp_session_id: fingerprintSessionId,
                  authentication_id: creditCardToken.authentication_id,
                  billing_details: creditCardData.billing_details,
                  installment: creditCardData.installment,
                  promotion_reference_id:
                    creditCardData.promotion?.reference_id,
                  billing_details_required:
                    creditCardData.billing_details_required,
                  cardholder_name: creditCardData.cardholder_name,
                  email: creditCardData.email,
                  set_submitting: creditCardData.set_submitting
                });
              }
            }
          } else if (creditCardToken.status === "IN_REVIEW") {
            // handle 3ds
            handleInReviewToken(creditCardToken);
          } else if (creditCardToken.status === "FAILED") {
            creditCardData.set_submitting(false);
            const title = creditCardToken.failure_reason || "Payment failed";

            const body =
              creditCardToken.failure_reason ||
              "Please try again or use another payment method.";
            // handle failed tokenization
            handleCreditCardError({
              title: title as string,
              body: body as string
            });
          }
        }
      );
    },
    []
  );

  const creditCardValidationErrors = (() => {
    if (!state.chargeOptions) {
      return null;
    }

    const errors: CreditCardValidationErrors = {};
    // check the validity of the card number
    const isCardNumberValid = (() => {
      // based on allowed bins
      if (
        invoice.channel_properties?.cards?.allowed_bins &&
        !invoice.channel_properties.cards.allowed_bins.includes(
          state.chargeOptions.bin_data.bin_number
        ) &&
        !invoice.channel_properties.cards.allowed_bins.find((bin) =>
          state.chargeOptionsParams?.cardNumber.startsWith(bin)
        )
      ) {
        return false;
      }

      // based on installment terms
      const allowedTerms =
        invoice.channel_properties?.cards?.installment_configuration
          ?.allowed_terms || [];
      if (
        invoice.channel_properties?.cards?.installment_configuration
          ?.allow_full_payment === false &&
        allowedTerms.length > 0
      ) {
        const allowedIssuers = allowedTerms.reduce((agg, term) => {
          return [...agg, term.issuer];
        }, [] as string[]);
        if (!allowedIssuers.includes(state.chargeOptions.bin_data.bank_code)) {
          return false;
        }
      }

      return true;
    })();

    if (!isCardNumberValid) {
      errors.cardNumber =
        "This card is not accepted for this transaction. Please use a different card.";
    }

    return errors;
  })();

  return (
    <CreditCardPaymentContext.Provider
      value={{
        ...state,
        invoiceId: invoice.id,
        onCreditCardError: handleCreditCardError,
        onTokenInReview: handleInReviewToken,
        onTokenVerified: handleVerifiedToken,
        onCreateCybersourceCharge: handleCreateCybersourceCharge,
        onCreateSignature: handleCreateSafeAcceptanceCharge,
        onCreateToken: handleCreateToken,
        onSubmitPayment: handleSubmitPayment,
        onGetChargeOptions: handleGetChargeOptions,
        onSetCreditCardPromotion: handleSetPromotion,
        creditCardValidationErrors
      }}
    >
      {children}
      <Dialog
        open={state.status === "error"}
        title={state.error?.title}
        description={state.error?.body}
        buttons={[
          {
            text: "OK, Got it!",
            variant: "brand-secondary",
            onClick: () => {
              dispatch({ type: "dismiss_error" });
            }
          }
        ]}
      />
    </CreditCardPaymentContext.Provider>
  );
};

export default CreditCardPaymentProvider;
export const useCreditCardPayment = () => useContext(CreditCardPaymentContext);
