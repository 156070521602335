/* eslint-disable @typescript-eslint/no-empty-function */
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useReducer,
  useCallback,
  useEffect
} from "react";
import {
  PaymentChannelsEnum,
  PaymentChannelsInterface,
  PaymentMethodsEnum,
  PropertiesFormFieldsType
} from "@xendit/checkout-utilities";
import { AxiosError } from "axios";
import startCase from "lodash/startCase";

import { useLegacyAsyncPayment } from "../LegacyAsyncPaymentContext";
import { usePaymentLink } from "../PaymentLinkContext";
import { usePaymentMethod } from "../PaymentMethodContext";
import { usePaymentRequest } from "../PaymentRequestContext";

import Dialog from "../../components/Dialog";

import {
  simulateOtcPayment,
  simulateVirtualAccountPayment,
  simulatePaymentMethod
} from "../../utils/fetch-resource";
import {
  SIMULATION_OTP_CODE,
  PAYMENT_FLOW_TYPES_AVAILABLE_FOR_SIMULATION
} from "../../utils/simulation";
import { logFetchUnexpectedResponse } from "../../utils/rum";

type SimulationStatus = "idle" | "ready" | "simulating" | "done";
type SimulationContextValues = {
  onReset: () => void;
  paymentChannelDisplayName: string;
  status: SimulationStatus;
  shouldShowCompletedMessage: boolean;
  shouldShowSimulatingMessage: boolean;
  shouldShowSimulateButton: boolean;
  onSimulatePayment: () => void;
  onSimulateOTPInput: () => void;
};

const SimulationContext = createContext<SimulationContextValues>({
  onReset: () => {},
  paymentChannelDisplayName: "",
  status: "idle",
  shouldShowCompletedMessage: false,
  shouldShowSimulatingMessage: false,
  shouldShowSimulateButton: false,
  onSimulatePayment: () => {},
  onSimulateOTPInput: () => {}
});

type SimulationReducerState = {
  error: null | ErrorMessage;
  showError: boolean;
  status: SimulationStatus;
};

type SimulationReducerAction =
  | { type: "completed" }
  | { type: "dismiss_error" }
  | { type: "initialized" }
  | { type: "reset" }
  | { type: "set_error"; payload: SetErrorPayload }
  | { type: "simulating" };
type SetErrorPayload = {
  error: ErrorMessage;
};

const SimulationReducer = (
  state: SimulationReducerState,
  action: SimulationReducerAction
): SimulationReducerState => {
  switch (action.type) {
    case "initialized":
      return { ...state, status: "ready" };
    case "completed":
      return { ...state, status: "done" };
    case "simulating":
      return { ...state, error: null, status: "simulating" };
    case "reset":
      return { ...state, error: null, showError: false, status: "idle" };
    case "set_error":
      return {
        ...state,
        error: action.payload.error,
        status: "ready",
        showError: true
      };
    case "dismiss_error":
      return { ...state, showError: false };
    default:
      return state;
  }
};

type Props = {
  children?: ReactNode;
};

const SimulationProvider: FC<Props> = (props) => {
  const [state, dispatch] = useReducer(SimulationReducer, {
    error: null,
    showError: false,
    status: "idle"
  });

  const {
    paymentLink: {
      invoice: { id, currency, callback_virtual_account_collection_id: cvacId }
    }
  } = usePaymentLink();
  const { checkoutPaymentChannels, paymentChannel, paymentMethodType } =
    usePaymentMethod();
  const { paymentRequest } = usePaymentRequest();

  const propertiesByCurrencyType =
    paymentChannel?.properties_by_currency &&
    paymentChannel?.properties_by_currency[currency]?.type;
  const channelPaymentType =
    propertiesByCurrencyType || paymentChannel?.properties.type || ""; // Added empty string as fallback to prevent undefined type

  const triggerFormEvent = (
    elements: { target: HTMLElement; value: string | boolean }[],
    triggerButton: boolean,
    triggerDelayFunc?: () => Promise<void>
  ): void => {
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      "value"
    )?.set;
    const inputEvent = new Event("input", { bubbles: true });

    elements.forEach((e) => {
      if (nativeInputValueSetter && e.target instanceof HTMLInputElement) {
        setTimeout(() => {
          nativeInputValueSetter.call(e.target, e.value);
          e.target.dispatchEvent(inputEvent);
        }, 0);
      }
    });

    if (triggerButton) {
      setTimeout(async () => {
        if (triggerDelayFunc) {
          await triggerDelayFunc();
        }
        const payNowButton = document.querySelector(
          '[data-testid="pay-now"]'
        ) as HTMLElement | null;

        if (payNowButton) {
          payNowButton.click();
        }
      }, 500);
    }
  };

  const {
    isCreating: isCreatingPaymentInstrument,
    otherBanksChannel,
    paymentInstrument
  } = useLegacyAsyncPayment();

  const simulatePaymentForm = () => {
    let inputElement = null;
    let elements:
      | { target: HTMLElement; value: string | boolean }[]
      | { target: HTMLElement; value: string }[] = [];

    paymentChannel?.properties?.fields?.forEach(
      (data: PropertiesFormFieldsType) => {
        const inputName = data.name;
        inputElement = document.getElementsByName(inputName)[0];
        elements = [
          {
            target: inputElement,
            value: inputName === "cashTag" ? "$abc" : "0888936448"
          }
        ];
      }
    );

    triggerFormEvent(elements, true);
    handleSimulated();
  };

  const simulatePaymentQR = () => {
    const handleEwalletQrCodeSimulation = () => {
      const qrElement = document.getElementById("qr-code-canvas-container");

      if (qrElement) {
        qrElement.setAttribute("target", "_self");
        qrElement.click();
      }
    };

    const sendSimulationRequest = async () => {
      try {
        await simulatePaymentMethod(
          paymentRequest?.payment_method.id as string,
          id
        );
        dispatch({ type: "completed" });
      } catch (error) {
        let errorCode = "Simulation Error";
        let errorMessage =
          "Failed to simulate payment. Please try again later.";

        if (error instanceof Error) {
          errorMessage = error.message;
        }
        if (error instanceof AxiosError) {
          const errorResponse = error.response?.data;
          errorCode = startCase(errorResponse.error_code.toLowerCase());
          errorMessage = errorResponse.message;
        } else {
          logFetchUnexpectedResponse(error);
        }

        dispatch({
          type: "set_error",
          payload: {
            error: {
              title: errorCode,
              body: errorMessage
            }
          }
        });
      }
    };

    switch (paymentChannel?.payment_method) {
      case "EWALLET":
        handleEwalletQrCodeSimulation();
        handleSimulated();
        break;
      case "QR_CODE":
        handleSimulating();
        sendSimulationRequest();
        break;
      default:
        break;
    }
  };

  const simulatePaymentDebitCard = () => {
    const futureYear = new Date().getFullYear() + 3;
    const twoDigitsYear = futureYear.toString().substring(2);

    const elements = [
      { target: document.getElementsByName("firstName")[0], value: "John" },
      { target: document.getElementsByName("lastName")[0], value: "Doe" },
      {
        target: document.getElementsByName("email")[0],
        value: "customer@website.com"
      },
      {
        target: document.getElementsByName("mobileNumber")[0],
        value: "+6287779955555"
      },
      { target: document.getElementsByName("cardLastFour")[0], value: "8888" },
      {
        target: document.getElementsByName("cardExpiry")[0],
        value: `02/${twoDigitsYear}`
      }
    ];

    triggerFormEvent(elements, true);
    handleSimulated();
  };

  const simulateCreditCardPayment = () => {
    const futureYear = new Date().getFullYear() + 3;
    const twoDigitsYear = futureYear.toString().substring(2);

    const elements = [
      {
        target: document.querySelector(
          '[data-testid="card-number"]'
        ) as HTMLElement,
        value: "4000000000001091"
      },
      {
        target: document.querySelector(
          '[data-testid="valid-thru"]'
        ) as HTMLElement,
        value: `02/${twoDigitsYear}`
      },
      {
        target: document.querySelector('[data-testid="cvv"]') as HTMLElement,
        value: "123"
      }
    ];

    triggerFormEvent(elements, true);
    handleSimulated();
  };

  const simulatePaymentAsync = () => {
    if (!paymentChannel || !paymentInstrument) {
      return;
    }

    handleSimulating();

    const sendSimulationRequest = async () => {
      try {
        switch (paymentChannel.payment_method) {
          case PaymentMethodsEnum.BANK_TRANSFER:
            await simulateVirtualAccountPayment(
              id,
              paymentChannel.channel === PaymentChannelsEnum.OTHER_BANKS
                ? otherBanksChannel
                : paymentChannel.channel,
              {
                collectionType: paymentInstrument.collection_type,
                bankAccountNumber: paymentInstrument.payment_destination
              }
            );
            dispatch({ type: "completed" });
            break;
          case PaymentMethodsEnum.RETAIL_OUTLET:
            await simulateOtcPayment(id, paymentChannel.channel, {
              paymentCode: paymentInstrument.payment_destination
            });
            dispatch({ type: "completed" });
            break;
          default:
            throw new Error("Payment method not supported for simulation");
        }
      } catch (error) {
        let errorCode = "Simulation Error";
        let errorMessage =
          "Failed to simulate payment. Please try again later.";

        if (error instanceof Error) {
          errorMessage = error.message;
        }
        if (error instanceof AxiosError) {
          const errorResponse = error.response?.data;
          errorCode = startCase(errorResponse.error_code.toLowerCase());
          errorMessage = errorResponse.message;
        } else {
          logFetchUnexpectedResponse(error);
        }

        dispatch({
          type: "set_error",
          payload: {
            error: {
              title: errorCode,
              body: errorMessage
            }
          }
        });
      }
    };
    sendSimulationRequest();
  };

  const handleSimulatePayment = () => {
    if (paymentMethodType === PaymentMethodsEnum.CREDIT_CARD) {
      return simulateCreditCardPayment();
    }

    switch (channelPaymentType) {
      case "FORM":
        simulatePaymentForm();
        break;
      case "QR_CODE":
        simulatePaymentQR();
        break;
      case "DEBIT_CARD":
        simulatePaymentDebitCard();
        break;
      case "TABS":
      case "STEPS":
        simulatePaymentAsync();
        break;
      default:
        break;
    }
  };

  const handleReset = useCallback(() => {
    dispatch({ type: "reset" });
  }, [dispatch]);

  const handleSimulated = useCallback(() => {
    dispatch({
      type: "completed"
    });
  }, [dispatch]);

  const handleSimulating = useCallback(() => {
    dispatch({
      type: "simulating"
    });
  }, [dispatch]);

  const handleSimulateOTPInput = () => {
    const elements: { target: HTMLElement; value: string | boolean }[] = [];

    SIMULATION_OTP_CODE.split("").forEach((char, index) => {
      elements.push({
        target: document.getElementsByName(`one-time-code-input-${index}`)[0],
        value: char
      });
    });

    triggerFormEvent(elements, false);
  };

  // set status to "ready" when channel is selected
  useEffect(() => {
    handleReset();
    if (
      paymentChannel &&
      !["QR_CODE", "TABS", "STEPS"].includes(channelPaymentType)
    ) {
      dispatch({ type: "initialized" });
    }
  }, [dispatch, handleReset, paymentChannel]);

  // set status to "ready" when applicable channel(s) payment request is ready
  useEffect(() => {
    if (
      paymentChannel &&
      ["QR_CODE"].includes(channelPaymentType) &&
      paymentRequest
    ) {
      dispatch({ type: "initialized" });
    }
  }, [paymentChannel, paymentRequest]);

  // set status to "ready" when async channel is ready
  useEffect(() => {
    if (
      paymentChannel &&
      ["TABS", "STEPS"].includes(channelPaymentType) &&
      paymentInstrument.payment_destination &&
      !isCreatingPaymentInstrument
    ) {
      dispatch({ type: "initialized" });
    }
  }, [isCreatingPaymentInstrument, paymentChannel, paymentInstrument, cvacId]);

  const isCreditCard = paymentMethodType === PaymentMethodsEnum.CREDIT_CARD;
  const availableForSimulation = paymentChannel
    ? PAYMENT_FLOW_TYPES_AVAILABLE_FOR_SIMULATION.includes(
        channelPaymentType
      ) &&
      paymentChannel.payment_method !==
        ("CRYPTOCURRENCY" as PaymentMethodsEnum) &&
      !paymentChannel.channel.includes("ONLINE_BANKING") // No simulation for dragonpay
    : false;

  const shouldShowSimulateButton =
    (isCreditCard && state.status === "idle") ||
    (availableForSimulation && state.status === "ready") ||
    false;

  const shouldShowCompletedMessage =
    !!paymentChannel &&
    state.status === "done" &&
    ["TABS", "STEPS"].includes(channelPaymentType);

  const shouldShowSimulatingMessage = state.status === "simulating";

  const paymentChannelDisplayName = (() => {
    if (paymentMethodType === PaymentMethodsEnum.CREDIT_CARD) {
      return "Credit Card";
    }

    if (!paymentChannel) {
      return "";
    }

    // return the available "other bank" channel name if the selected channel is "other banks"
    if (paymentChannel.channel === PaymentChannelsEnum.OTHER_BANKS) {
      const otherBanksCheckoutPaymentChannel =
        checkoutPaymentChannels[
          otherBanksChannel as keyof PaymentChannelsInterface
        ];
      return (
        otherBanksCheckoutPaymentChannel?.display_name ||
        otherBanksCheckoutPaymentChannel?.channel ||
        ""
      );
    }
    return paymentChannel?.display_name || paymentChannel?.channel || "";
  })();

  return (
    <SimulationContext.Provider
      value={{
        ...state,
        onReset: handleReset,
        onSimulatePayment: handleSimulatePayment,
        onSimulateOTPInput: handleSimulateOTPInput,
        paymentChannelDisplayName,
        shouldShowCompletedMessage,
        shouldShowSimulateButton,
        shouldShowSimulatingMessage
      }}
    >
      {props.children}

      <Dialog
        open={!!state.showError}
        title={state.error?.title}
        description={state.error?.body}
        buttons={[
          {
            text: "OK, Got it!",
            variant: "brand-secondary",
            onClick: () => {
              dispatch({ type: "dismiss_error" });
            }
          }
        ]}
      />
    </SimulationContext.Provider>
  );
};

export default SimulationProvider;

export const useSimulation = () => useContext(SimulationContext);
